body,
h1,
h2,
h3,
h4,
h5 {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}
.contact,
.aboutus,
.productBody,
.error-page {
  animation: blinker 0.7s linear 1;
}
@keyframes blinker {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.App {
  background-color: white;
  text-align: center;
  color: blue;
  height: 100%;
}

.headerStyle {
  color: black;
  height: 100px;
  /* background-color: #6c0d31; */
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.751),
    rgba(255, 255, 255, 0.287)
  );
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  opacity: 0.75;
  z-index: 10;
  transition: 1s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .ui.item.simple.dropdown,
  .ui.active.visible.item.simple.dropdown,
  .ui.vertical.menu .item {
    font-size: 12px !important;
    padding: 3px !important;
    font-weight: 600 !important;
  }
  .ui.vertical.menu.active.item {
    background: transparent !important;
  }

  .ui.vertical.menu .active.dropdown.item {
    border-radius: 6px !important;
  }
  #verticalMenu {
    margin: 1px !important;
  }
  .headerStyle {
    height: 60px;
  }
  .headerLeft {
    gap: 5px !important;
  }
  .headerRight {
    justify-content: center;
    width: 70% !important;
    align-items: center;
    padding: 0px 15px;
  }

  .alpaText {
    font-size: 8px;
  }
  .almilonText {
    font-size: 18px;
  }
  .ant-dropdown-trigger {
    padding: 3px !important;
  }
  .menuButtons {
    font-size: 12px;
    /* padding: 6px; */
  }
  #ProductMenu {
    font-size: 12px !important;
  }

  .ant-space-item {
    font-size: 12px;
  }
  .videoWrapper {
    position: absolute;
    height: 100%;
    /* padding-bottom: 56.25%; */
    top: 0;
    bottom: 0;
    z-index: 0;
  }
  video {
    transform: translateX(-400px);
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }
  .ui.vertical.menu .item {
    font-size: 12px !important;
    padding: 3px !important;
  }

  /* Products menüsü için özel stil */
  .ui.vertical.menu {
    font-size: 12px !important;
    font-weight: 600 !important;
    background-color: rgba(255, 255, 255, 0.01) !important;
    height: 32px !important;
    padding: 4px 15px !important;
    display: flex !important
;
    justify-content: center !important;
  }

  /* Alt menü öğeleri için */
  .ui.vertical.menu .dropdown.item .menu {
    font-size: 12px !important;
    font-weight: 600 !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
  }

  .ui.vertical.menu .dropdown.item .menu .item {
    font-weight: 600 !important;
  }
}

.headerLeft {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 1.2s;
  border-right: 1px solid black;
  font-family: serif !important;
}
.headerLeft:hover {
  gap: 0px;
}

.headerRight {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 0.5;
  z-index: 10;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.videoWrapper {
  background-color: black;
  width: 100%;
  position: absolute; /*absolut idi değiştirdim*/
  z-index: 0;
  height: 100%;
  /* left: 50%;
  transform: translate(-50%, 0); */
  /* transform: scale(1.1); */
  /* height: 56.25%; */
  /* margin-top: 15px; */
  top: 0;
  bottom: 0;
}
video {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}
.alpaText {
  font-family: roboto;
  font-size: 20px;
  margin: 0;
}
.almilonText {
  font-family: serif;
  margin: 0;
}

.alpaText:hover,
.almilonText:hover {
  cursor: pointer;
}

.footer {
  height: 25px;
  width: 100%;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.485), rgba(255, 255, 255, 0.178));
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: 1px solid black;
  color: black;
  position: fixed;
  bottom: 0;
  z-index: 10;
}

.productBody {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none !important;
  background-color: #4b080f;
  padding-bottom: 25px; /* footer yüksekliği kadar padding */
}

.productcard {
  padding-top: 102px;
  min-height: 90vh;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #4b080f;
  color: wheat;
  white-space: pre-line;
  text-align: justify;
  scrollbar-width: none !important;
}

.ant-dropdown {
  background-color: rgba(255, 255, 255, 0.636);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.ant-dropdown-menu {
  background-color: #fff0;
}

.ant-dropdown-trigger {
  cursor: pointer !important;
  padding: 5px 15px 5px 15px;
  background-color: rgba(255, 255, 255, 0.01);
  border-radius: 5px;
}

.ant-dropdown-menu-item-group {
  cursor: pointer !important;
  border-radius: 10px;
}

.ant-dropdown-menu-item-group-title {
  color: black !important;
}

.ant-dropdown-menu-item-group:hover {
  background-color: white;
}

.menuButtons,
.ant-dropdown-trigger.ui,
.ui.vertical.menu {
  font-weight: 800;
  cursor: pointer !important;
  transition: 1s;
  background-color: rgba(255, 255, 255, 0.01);
}

.ui.menu .dropdown menu > .item {
  font-weight: 800 !important;
}

.menuButtons:hover,
.ant-dropdown-trigger:hover,
.ui.vertical.menu:hover {
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.aboutus {
  padding-top: 252px;
  background-color: #4b080f;
  height: auto;
  color: wheat;
  padding-right: 5%;
  padding-left: 5%;
  text-align: justify;
  font-size: 17px;
  font-weight: 400;
  white-space: pre-line;
}
.aboutus > h4 {
  margin-top: 0;
}
a {
  color: inherit;
}

.contact {
  padding-top: 140px;
  background-color: #4b080f;
  height: auto;
  color: wheat;
  padding-right: 5%;
  padding-left: 5%;
  text-align: justify;
  font-size: 17px;
  font-weight: 600;
  white-space: pre-line;
  line-height: 30px;
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #6c0d31;
  background-color: #d7a77f;
}

.error-page h2 {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.error-page h3 {
  font-size: 1.5rem;
}

.ui.menu {
  min-height: unset !important;
}

.ui.compact.menu {
  background-color: #fff0 !important;
  border: none !important;
  box-shadow: none !important;
  transition: 1s;
  border-width: 6px;
}

.ui.compact.menu:hover {
  border: 1px solid black !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.ui.item.simple.dropdown,
.ui.active.visible.item.simple.dropdown,
.ui.vertical.menu .item {
  padding: 4px 15px !important;
  height: 32px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 14px;
  font-weight: 800;
}

.menu.transition > .item > span {
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.menu.transition {
  border-radius: 10px !important;
}

.contact {
  font-size: 17px !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  line-height: 40px;
}

.contact > h3 {
  font-size: 18px !important;
  font-weight: bold !important;
  margin: 30px 0px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.contact > a {
  color: #aafdb8;
}

.aboutus,
.aboutus > h4 {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 17px !important;
  line-height: 1.3;
}

.aboutus > h4 {
  margin-bottom: 20px !important;
}

.productcard {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.productcard > h5 {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.productcard > h4 {
  margin: 30px 0px !important;
  font-size: 16px;
  font-weight: 500;
}

.headerLeft > .alpaText,
.headerLeft > .almilonText {
  font-family: serif !important;
}
#dropdownmenu > .ui.dropdown .menu {
  transform: translateY(35px);
  z-index: 21 !important;
}
.ui.vertical.menu .dropdown.item .menu {
  left: -50% !important;
  min-width: 0;
  margin: 0;
  border-radius: 0 0.28571429rem 0.28571429rem 0.28571429rem;
}
#dropdownmenu {
  transform: translateY(50px);
}

.ui.vertical.menu {
  background-color: #fff0 !important;
  border: 1px solid #00000000 !important;
  box-shadow: none !important;
  width: fit-content !important;
  min-width: 0px !important;
  margin: 0 1px !important;
}

.ui.vertical.menu:hover {
  border: 1px solid black !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.ui.vertical.menu .dropdown.item .menu {
  background-color: rgba(255, 255, 255, 0.9) !important;
  /*border-radius: 16px !important;*/
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(2px) !important;
  -webkit-backdrop-filter: blur(2px) !important;
  border: none !important;
  left: 0px !important;
  min-width: 50px !important;
}
/*  buna bakalım
}

/* Masaüstü için ana menü stilleri */
.ui.vertical.menu .item {
  font-weight: 800 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 32px !important;
  padding: 4px 15px !important;
  font-size: 14px !important;
}

/* Masaüstü için alt menü stilleri */
.ui.vertical.menu .dropdown.item .menu .item {
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.95) !important;
  justify-content: flex-start !important;
}
#submenu {
  transform: translate(7px, 32px) !important;
  z-index: 21 !important;
  background-color: rgba(255, 255, 255, 1) !important;
  opacity: 1 !important;
}
