body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  background-color: black;
  scrollbar-width: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body::-webkit-scrollbar,
.productBody::-webkit-scrollbar {
  display: none;
  scrollbar-width: none !important;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  scrollbar-width: none !important;
  background-color: white;
}
